require( 'owl.carousel' );

import nav from './modules/nav';
import music from './modules/music';
import tours from './modules/tours';
import video from './modules/video';

jQuery( document ).ready(function($) {
  // init header nav

  nav();

  // init music section
  music();

  // init tours section
  tours();

  // init video section
  video();

});
