
export default function music() {

  $('#music .slider-music').owlCarousel({
    items: 1,
    dotsEach: false,
    loop: true,
    nav: true,
    smartSpeed: 1000,
    mouseDrag: false,
    navText: ['<','>']
  });

}